import axios from 'axios'

const service = axios.create({
  baseURL: 'https://api.s777.club/', // 'https://demo-api.s777.club/', 
  timeout: 60000
})

service.interceptors.request.use(
  config => {
    if (window.localStorage.getItem('authToken')) {
      config.headers['x-access-token'] = window.localStorage.getItem('authToken')
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export default service
